// Color system

$white:    #ffffff;
$semi-white:    rgba(255, 255, 255, 0.95);
$gray-100: #f8f9fa !default;
$gray-200: #e6e6e6;
$gray-300: #dbe5e7;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #516a7f;
$gray-700: #495057 !default;
$gray-800: #34454f;
$gray-900: #0f1e33;
$black:    #000000;
$semi-black:    rgba(0, 0, 0, 0.95);

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue":       $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":       $pink,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
	"white":      $white,
	"semi-white": $semi-white,
	"black":      $black,
	"semi-black": $semi-black,
    "gray":       $gray-600,
    "gray-dark":  $gray-800,
    "default":    $gray-200
  ),
  $colors
);

$primary:       #087e2a; 
$secondary:     #455a64;
$tertiary:      #cedfd0;
$quaternary:	#ced4d7;
$accent:        #ffe000;
$basecamp:      $black;
$headings2:     white;
$success:       $green !default;
$info:          #d5d5d7;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-900;
$default:       $gray-200;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
	"tertiary":   $tertiary,
	"quaternary": $quaternary,
    "accent":     $accent,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark,
    "default":    $default
  ),
  $theme-colors
);

// Set a specific jump point for requesting color jumps
$theme-color-interval:      8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $gray-900 !default;
$yiq-text-light:            $white !default;
