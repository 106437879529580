@import 'variables';

body {
	overflow-y: scroll;
	min-height: 100%;

	p {
		&:last-child {
			margin-bottom: 0px;
		}
	}

	b,
	strong {
		font-weight: $font-weight-bold !important;
	}
}

// BOOTSTRAP OVERRIDES
.bg-semi-white {
	background-color: $semi-white !important;
}

.navbar-toggler {
	border: none;
}

.navbar-light {
	.navbar-toggler {
		color: $secondary;
	}
}

// CUSTOM STYLES
.c-pointer {
	cursor: pointer;
}

.c-na {
	cursor: not-allowed;
}

.handwritten-underline {
	background: url(/assets/underlines.svg) bottom center/100% 0.2em no-repeat;
	padding-bottom: 0.05em;
}

.text-underline {
	text-decoration: underline;
}

.font-size-lg {
	font-size: $font-size-lg;
}
