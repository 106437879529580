// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:              1.5 !default;
$line-height-sm:              1.5 !default;

$border-width:                0.0625rem;
$border-color:                $gray-200;

$border-radius:               .25rem !default;
$border-radius-lg:            .3rem !default;
$border-radius-sm:            .2rem !default;

$rounded-pill:                50rem !default;

$box-shadow-sm:               0 .125rem .25rem rgba($black, .075) !default;
$box-shadow:                  0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-lg:               0 1rem 3rem rgba($black, .175) !default;

$component-active-color:      $white !default;
$component-active-bg:         theme-color("primary") !default;

$caret-width:                 .3em !default;
$caret-vertical-align:        $caret-width * .85 !default;
$caret-spacing:               $caret-width * .85 !default;

$transition-base:             all .2s ease-in-out !default;
$transition-fade:             opacity .15s linear !default;
$transition-collapse:         height .35s ease !default;

$embed-responsive-aspect-ratios: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$embed-responsive-aspect-ratios: join(
  (
    (21 9),
    (16 9),
    (4 3),
    (1 1),
  ),
  $embed-responsive-aspect-ratios
);
